import React from 'react'
import { Link } from 'gatsby'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'
import LocalizedLink from '../LocalizedLink'

import locales from '../../constants/locales'
import styles from './header.module.css'

const Header = ({ intl: { locale } }) => (


  <div className={styles.container}>

  <div className={styles.header}>
  
    <div className={styles.headergroup}>
    
      <LocalizedLink to="/">
        <img src={require('../../Images/logo4.svg')} height="40" />
      </LocalizedLink>
  
      <LocalizedLink to="/rooms/">
        <FormattedMessage id="OurRooms" />
      </LocalizedLink>
      <LocalizedLink to="/services/">
        <FormattedMessage id="Services" />
      </LocalizedLink>
      <LocalizedLink to="/location/">
        <FormattedMessage id="Location" />
      </LocalizedLink>
      <LocalizedLink to="/about/">
        <FormattedMessage id="about" />
      </LocalizedLink>
      <LocalizedLink to="/contact/">
        <FormattedMessage id="Contact" />
      </LocalizedLink>
     
      <div className={styles.language}>
        {Object.keys(locales).map(key => (
          <span>
            {' '}
            <Link
              className={key === locale ? styles.active : ''}
              key={locales[key].locale}
              to={locales[key].default ? '/' : `/${locales[key].path}`}
            >
              {locales[key].locale}
            </Link>
          </span>
        ))}
      </div>
    </div>
  </div>

  </div>

  
)

Header.propTypes = {
  intl: intlShape.isRequired
}

export default injectIntl(Header)
