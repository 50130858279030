import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { IntlProvider, addLocaleData, FormattedMessage } from 'react-intl'
import Helmet from 'react-helmet'

// Locale data
import enData from 'react-intl/locale-data/en'
import elData from 'react-intl/locale-data/el'

// Messages
import el from '../../i18n/el.json'
import en from '../../i18n/en.json'

// Style
import './index.css'

// Components
import Header from '../Header/header'
import Footer from '../footer'
import Sidebar from '../sidebar'


const messages = { el, en }

addLocaleData([...elData, ...enData])

const Layout = ({ locale, children }) => (
  <IntlProvider locale={locale} messages={messages[locale]}>
    <Fragment>
      <Helmet
        title="Ξενοδοχείο Κύμα - Σαμοθράκη / Hotel Kima - Samothrace"
        meta={[
          {
            name: 'description',
            content:
              'Το ξενοδοχείο είναι μία μικρή οικογενειακή επιχείρηση στο νησί της Σαμοθράκης στην Καμαριώτισσα, - το λιμάνι του νησιού - με εμπειρία στο χώρο της φιλοξενίας για περίπου τέσσερις δεκαετίες. Οι επισκέπτες μας από όλα τα μέρη του κόσμου μένουν πάντα ικανοποιημένοι από το ζεστό περιβάλλον και την φιλοξενία μας.'
          },
          {
            name: 'keywords',
            content:
              'διακοπές,δωμάτια,ξενοδοχείο,Σαμοθράκη,φιλοξενία,Καμαριώτισσα, Hotel, Samothrace, Kamariotissa, Rooms, services,location,vocation'
          }
        ]}
      />
      <Header />
      <Sidebar />
      {children}
      <Footer />
    </Fragment>
  </IntlProvider>
)

Layout.propTypes = {
  locale: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
}

export default Layout
