module.exports = {
  el: {
    path: 'el',
    locale: 'GR/',
    default: true
  },
 en: {
    path: 'en',
    locale: 'EN'
  }
}
