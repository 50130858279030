import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import LocalizedLink from '../components/LocalizedLink'

const FooterGroup = styled.div`
  background: white;
  padding: 50px 0px;
  display: grid;
  grid-gap: 20px;
  margin-top: 50px;
`

const Text = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: black;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  width: 80%;
`

const TextEspa = styled.p`
  font-size: 18px;
  color: black;
  margin: auto;
  text-align: center;
  width: 50%;
`
const LinkGroup = styled.div`
  margin: 20px auto;
  display: grid;
  grid-gap: 50px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 720px) {
    grid-gap: 20px;
    grid-template-columns: repeat(1, 1fr);
  }

  a {
    transition: 0.8s;
    font-weight: 400;
  }

  a:hover {
    color: #293a7e;
  }

  ul {
    list-style: none;
    padding: 0px;
    @media (max-width: 720px) {
      margin: auto;
      text-align: center;
    }
  }

  li {
    margin: 15px auto;
    font-weight: 600;
  }
`

const Copyright = styled.div`
  color: gray;
  max-width: 500px;
  margin: 0 auto;
  padding: 0 20px;
`

const Espa = styled.div`
  color: gray;
  margin: auto;
  display: grid;
  grid-gap: 24px;

  img{
    margin: auto;
  }
`

const Footer = () => (
  <FooterGroup>
    <Text>
      <FormattedMessage id="contactusformore" />
    </Text>

    {/* <Button>Call Us</Button> */}

    <LinkGroup>
      <ul>
        <li>
          <FormattedMessage id="Kyma" />
        </li>
        <li>
          <LocalizedLink to="/">
            <FormattedMessage id="Home" />
          </LocalizedLink>
        </li>
        <li>
          {' '}
          <LocalizedLink to="/rooms/">
            <FormattedMessage id="OurRooms" />
          </LocalizedLink>
        </li>
        <li>
          <LocalizedLink to="/services/">
            <FormattedMessage id="Services" />
          </LocalizedLink>
        </li>
        <li>
          <LocalizedLink to="/location/">
            <FormattedMessage id="Location" />
          </LocalizedLink>
        </li>
        <li>
          <LocalizedLink to="/about/">
            <FormattedMessage id="about" />
          </LocalizedLink>
        </li>
      </ul>
      <ul>
        <li>
          <FormattedMessage id="Contact" />
        </li>
        <li>
          <a href="tel:2551041263">
            <FormattedMessage id="phone" />
          </a>
        </li>
        <li>
          <a href="tel:6970398168">
            <FormattedMessage id="mobile" />
          </a>
        </li>
        <li>
          <a href="mailto:hotel.kima.samothraki@gmail.com" target="_top">
            <FormattedMessage id="email" />
          </a>{' '}
        </li>
      </ul>
    </LinkGroup>
    <Copyright>@copyrights Kima Hotel</Copyright>
    
    <Espa>        
        <img src={require('../Images/espalogo.png')} height="80" />
        <TextEspa>
        Η επιχείρηση έχει συμπεριληφθεί στο πρόγραμμα του Ευρωπαϊκού Ταμείου Περιφερειακής Ανάπτυξης (ΕΤΠΑ) που αφορά την Ενίσχυση επιχειρήσεων για την αντιμετώπιση των συνεπειών της πανδημίας COVID – 19 στην Ανατολική Μακεδονία και Θράκη          
    </TextEspa>


                            
        </Espa>

  </FooterGroup>
)

export default Footer
