import React from 'react'
import { scaleDown as BurgerMenu } from 'react-burger-menu'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import LocalizedLink from '../components/LocalizedLink'
import locales from '../constants/locales'

const Language = styled.div`
  a.active {
    text-decoration: none;
    pointer-events: none;
    opacity: 0.5;
  }

  a {
    text-align: center;
    display: inline-block;
    text-decoration: none;
    margin-bottom: 5vh;
    color: #d1d1d1;
    transition: color 0.2s;
  }

  a:hover {
    color: white;
  }
`
const StyledBurgerMenu = styled.div`
  @media (min-width: 720px) {
    display: none;
  }

  .bm-item {
    text-align: center;
    display: inline-block;
    text-decoration: none;
    margin-bottom: 5vh;
    color: #d1d1d1;
    transition: color 0.2s;
  }
  .bm-item:hover {
    color: white;
  }
  .bm-burger-button {
    position: fixed;
    width: 32px;
    height: 15px;
    right: 24px;
    top: 4vh;
  }
  .bm-burger-bars {
    background: #373a47;
  }
  .bm-cross-button {
    position: absolute;
    height: 45px !important;
    width: 45px !important;
    right: 5vw !important;
    top: 5vh !important;

    span {
      top: 6px !important;
      right: 3vh !important;
    }
  }
  .bm-cross {
    width: 3px !important;
    height: 35px !important;
    background: white;
  }
  .bm-menu {
    background: #293a7e;
    padding: 2.5em 1.5em 0;
    font-size: 2em;

    a {
      font-size: 30px;
    }
  }
  .bm-morph-shape {
    fill: #373a47;
  }
  .bm-item-list {
    color: white;
  }

  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
`

export default () => (
  <StyledBurgerMenu>
    <BurgerMenu width="100%">
      <LocalizedLink to="/">
        <FormattedMessage id="Home" />
      </LocalizedLink>
      <LocalizedLink to="/rooms/">
        <FormattedMessage id="OurRooms" />
      </LocalizedLink>
      <LocalizedLink to="/services/">
        <FormattedMessage id="Services" />
      </LocalizedLink>
      <LocalizedLink to="/location/">
        <FormattedMessage id="Location" />
      </LocalizedLink>
      <LocalizedLink to="/about/">
        <FormattedMessage id="about" />
      </LocalizedLink>
      <LocalizedLink to="/contact/">
        <FormattedMessage id="Contact" />
      </LocalizedLink>
      <Language>
        {Object.keys(locales).map(key => (
          <span>
            {' '}
            <Link
              key={locales[key].locale}
              to={locales[key].default ? '/' : `/${locales[key].path}`}
            >
              {locales[key].locale}
            </Link>
          </span>
        ))}
      </Language>
    </BurgerMenu>
  </StyledBurgerMenu>
)
